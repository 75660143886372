import {Clusterconfig} from "./clusterconfig";
import {Tokenstorage} from "./tokenstorage";
import {logMessage, getAppStateVersion, getDiagnostics} from "../taskpane";
import 'cross-fetch/polyfill';

export class NotarisdossierApi
{

    /**
     * @param username
     * @param password
     * @param office
     * @returns {Promise<unknown>}
     */
    static authenticate(username, password, office)
    {
        logMessage('NotarisdossierApi.authenticate()');
        return new Promise(function(resolve, reject) {
            const authenticateUrl = Clusterconfig.getUrl() + '/v3/rest/application/authenticate';
            const requestedScope = 'config:read';
            logMessage('-> Send POST to ' + authenticateUrl);
            let body = JSON.stringify({username: username, password: password, scope: requestedScope});
            if (office !== null) {
                body = JSON.stringify({username: username, password: password, scope: requestedScope, office_id: office});
            }

            fetch(authenticateUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-App-Name': 'Outlook add-in',
                    'X-App-Version': getAppStateVersion(),
                    'X-App-Diagnostics': getDiagnostics(),
                },
                body: body
            }).then((response) => {
                logMessage('-> Received response with status ' + response.status);
                return response.json();
            }).then((json) => {
                if (json.hasOwnProperty('message')) {
                    if (json.message === 'Authentication ambiguous') {
                        reject(json);
                    }
                    reject('Onjuiste combinatie van gebruikersnaam en wachtwoord');
                } else {
                    Tokenstorage.setTokens(json.access_token, json.refresh_token);
                }
                resolve();
            }).catch((e) => {
                logMessage(e);
                reject('Kan niet verbinden met server');
            });
        });
    }


    /**
     * @returns {Promise<unknown>}
     */
    static refreshTokens()
    {
        logMessage('NotarisdossierApi.refreshTokens()');
        return new Promise(function(resolve, reject) {
            const refreshTokenUrl = Clusterconfig.getUrl() + '/v3/rest/application/refresh-token';
            logMessage('-> Send POST to ' + refreshTokenUrl);
            fetch(refreshTokenUrl, {
                method: 'POST',
                body: JSON.stringify({refresh_token: Tokenstorage.getRefreshToken()}),
                headers: {
                    'Content-Type': 'application/json',
                    'X-App-Name': 'Outlook add-in',
                    'X-App-Version': getAppStateVersion(),
                    'X-App-Diagnostics': getDiagnostics(),
                },
            }).then((response) => {
                logMessage('-> Received response with status ' + response.status);
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 401) {
                    // This means refresh tokens are not valid, logout
                    reject(response);
                } else {
                    reject('error while refreshing tokens');
                }
            }).then((json) => {
                Tokenstorage.setTokens(json.access_token, json.refresh_token);
                resolve();
            }).catch(() => {
                reject('unknown error');
            });
        });
    }


    /**
     * @returns {Promise<void>}
     */
    static async refreshTokenIfNeeded()
    {
        if (Tokenstorage.isAccessTokenExpired()) {
            await NotarisdossierApi.refreshTokens();
        };
    }


    /**
     * Folder names that we want to fetch
     */
    static folderNameSettings = [
        'folder-name-emails',
        'folder-name-correspondence',
        'folder-name-drafts',
        'folder-name-instrument-copy',
        'folder-name-financial',
        'folder-name-identity-cards',
        'folder-name-identity-check',
        'folder-name-kadaster',
        'folder-name-kadaster-registration',
        'folder-name-kvk',
        'folder-name-uploaded-docs',
        'folder-name-instrument-original',
        'folder-name-registers',
        'folder-name-vis',
        'folder-name-wwft-check',
    ];


    /**
      * @returns {Promise<unknown>}
     */
    static async getSettings(settings)
    {
        await NotarisdossierApi.refreshTokenIfNeeded();

        logMessage('NotarisdossierApi.getSettings()');
        return new Promise(function(resolve, reject) {
            let getFoldersUrl = Clusterconfig.getUrl() + '/v3/rest/application/setting';
            logMessage('-> Send GET to ' + getFoldersUrl);
            getFoldersUrl += '/' + settings.join();
            fetch(getFoldersUrl, {
                method: 'GET',
                credentials: 'include',
                headers: {Authorization: 'Bearer ' + Tokenstorage.getAccessToken()},
            }).then((response) => {
                logMessage('-> Received response with status ' + response.status);
                if (response.status === 200) {
                    return response.json();
                } else {
                    reject('error while getting folder config')
                }
            }).then((json) => {
                resolve(json)
            }).catch(() => {
                reject('unknown error');
            });
        });
    }


    /**
     * @param term
     * @param isEmailSubject
     * @returns {Promise<unknown>}
     */
    static async searchFile(term, isEmailSubject = false)
    {
        await NotarisdossierApi.refreshTokenIfNeeded();

        logMessage('NotarisdossierApi.searchFile()');
        return new Promise(function(resolve, reject) {
            const queryParams = '?' + (isEmailSubject ? 'email-subject' : 'search') + '=' + encodeURIComponent(term);
            const searchFileUrl = Clusterconfig.getUrl() + '/v3/rest/core/file' + queryParams;
            logMessage('-> Send GET to ' + searchFileUrl);
            fetch(searchFileUrl, {
                method: 'GET',
                credentials: 'include',
                headers: {'Authorization': 'Bearer ' + Tokenstorage.getAccessToken()},
            }).then((response) => {
                logMessage('-> Received response with status ' + response.status);
                if (response.status === 200) {
                    return response.json();
                } else {
                    reject('Error bij het ophalen van dossiers');
                }
            }).then((json) => {
                resolve(json)
            }).catch(() => {
                reject('unknown error');
            });
        });
    }


    /**
     * @param fileUuid
     * @param name
     * @param folderName
     * @param content
     * @param isBase64
     * @returns {Promise<unknown>}
     */
    static async addFileInternalDocument(fileUuid, name, folderName, content, isBase64)
    {
        await NotarisdossierApi.refreshTokenIfNeeded();
        logMessage('NotarisdossierApi.addFileInternalDocument()');
        logMessage('-> File: ' + fileUuid);
        logMessage('-> Name: ' + name);
        logMessage('-> Folder: ' + folderName);

        return new Promise(function(resolve, reject) {
            const addInternalDocumentUrl = Clusterconfig.getUrl() + '/v3/rest/document/file-internal-document';
            const params = {
                file_uuid: fileUuid,
                name: name,
                folder: folderName,
                content: content,
                base64: isBase64
            };
            logMessage('-> Send POST to ' + addInternalDocumentUrl);
            fetch(addInternalDocumentUrl, {
                method: 'POST',
                body: JSON.stringify(params),
                credentials: 'include',
                headers: {
                    'Authorization': 'Bearer ' + Tokenstorage.getAccessToken(),
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                logMessage('-> Received response with status ' + response.status);
                if (response.status === 201) {
                    resolve();
                } else {
                    reject('Error bij het opslaan van bestand')
                }
            }).catch(() => {
                reject('unknown error');
            });
        });
    }


}