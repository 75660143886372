import { NotarisdossierApi } from "./notarisdossierApi";
import {logMessage, isMailboxAPISupported} from "../taskpane";
import {Tokenstorage} from "./tokenstorage";

export class MailParser
{

  /**
   * Category name for saved messages
   */
  static CATEGORY_NAME = 'Opgeslagen bij dossier';


  /**
   * Return a date like '2023-08-18 15:26:12' as '230818 1526'
   *
   * @param dateTime
   * @returns {string}
   */
  static formatDateTime(dateTime) {
    let year = dateTime.getYear().toString().substr(-2);
    let month = (dateTime.getMonth() + 1).toString();
    let day = dateTime.getDate().toString();
    let hours = dateTime.getHours().toString();
    let minute = dateTime.getMinutes().toString();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if (hours.length < 2) {
      hours = '0' + hours;
    }
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    return year + month + day + ' ' + hours + minute;
  }


  /**
   * @param filenameSetting
   * @param mailItem
   * @param userProfile
   * @returns {*}
   */
  static getEmlFilename(filenameSetting, mailItem, userProfile) {
    let dataMap = {
      '[datumtijd]': MailParser.formatDateTime(mailItem.dateTimeCreated),
      '[onderwerp]': mailItem.subject,
      '[afzender-naam]': mailItem.from.displayName,
      '[afzender-email]': mailItem.from.emailAddress,
      '[ontvanger-naam]': userProfile.displayName,
      '[ontvanger-email]': userProfile.emailAddress,
    };
    Object.keys(dataMap).forEach((key) => {
      filenameSetting = filenameSetting.replaceAll(key, dataMap[key]);
    });
    return filenameSetting.trim() + '.eml';
  };


  /**
   * @param mailItem
   * @param coercionType (Office.CoercionType.Html or Office.CoercionType.Text)
   * @returns {Promise<unknown>}
   */
  static mailItemGetBody(mailItem, coercionType) {
    logMessage('MailParser.mailItemGetBody(' + coercionType + ')');
    return new Promise((resolve, reject) => {
      mailItem.body.getAsync(coercionType, function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve(asyncResult.value);
        } else {
          reject("Er is een onbekende fout opgetreden");
        }
      });
    });
  }


  /**
   * The attachments are a property of the mail item. We need the content for all attachments.
   *
   * @param mailItem
   * @returns {Promise<unknown>}
   */
  static mailItemGetAttachmentsContent(mailItem){
    logMessage('-> MailParser.mailItemGetAttachmentsContent()');
    return new Promise((resolve, reject) => {
      try {
        if (mailItem.attachments.length > 0) {
          let promises = [];
          for (let i = 0; i < mailItem.attachments.length; i++) {
            let attachment = mailItem.attachments[i];
            promises.push(
              new Promise((resolve2, reject2) => {
                mailItem.getAttachmentContentAsync(attachment.id, {asyncContext: attachment}, function(result) {
                  if (result.status === Office.AsyncResultStatus.Succeeded) {
                    attachment.content = result.value.content;
                    resolve2(attachment);
                  } else {
                    reject2('Error getting attachment content');
                  }
                });
              })
            );
          }
          Promise.all(promises)
            .then((results) => {
              resolve(results);
            }).catch((e) => {
              reject(e);
            });
        } else {
          resolve([]);
        }
      } catch (e) {
        reject(e);
      }
    });
  }


  static legacyMailItemGetAttachmentsContent(mailItem)
  {
    logMessage('-> MailParser.legacyMailItemGetAttachmentsContent()');
    let token = Tokenstorage.getAttachmentToken();
    logMessage('-> token: ' + token.substring(0, 12));

    const ewsId = mailItem.itemId;
    const restId = Office.context.mailbox.convertToRestId(ewsId, Office.MailboxEnums.RestVersion.v2_0);

    return new Promise((resolve, reject) => {
      try {
        if (mailItem.attachments.length > 0) {
          let promises = [];
          let url = Office.context.mailbox.restUrl + '/v2.0/me/messages/' + restId + '/attachments'

          const call = {
            url: url,
            type: 'GET',
            headers: {'Authorization': 'Bearer ' + token},
          };

          logMessage('call to ' + url);
          $.ajax(call).then(function (result) {
            logMessage('-> result');
            for (let i = 0; i < result.value.length; i++) {
              let attachment = mailItem.attachments[i];
              promises.push(
                  new Promise((resolve2, reject2) => {
                    if (result.value[i].hasOwnProperty('ContentBytes')) {
                      attachment.content = result.value[i].ContentBytes;
                      resolve2(attachment);
                    } else {
                      reject2('Error getting attachment content');
                    }
                  })
              );
            }
          }).fail(function (error) {
            logMessage('-> fail');
            if (error.hasOwnProperty('responseJSON')) {
              logMessage('-> status: ' + error.status + ', message: ' + error.responseJSON.error.message);
            } else if (error.hasOwnProperty('responseText')) {
              logMessage('-> status: ' + error.status + ', message: ' + error.responseText);
            } else {
              logMessage('-> status: ' + error.status + ', message: ' + error.statusText);
            }
            reject('Error met ophalen bijlage.');
          }).then(() => {
            logMessage(' -> then all promises');
            Promise.all(promises)
                .then((results) => {
                  resolve(results);
                }).catch((e) => {
              reject(e);
            });
          });
        } else {
          resolve([]);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Parse the HTML as DOMdocument and set the Content-ID of all inline images as property of the inline attachments.
   *
   * @param html
   * @param attachments
   * @returns {string}
   */
  static setContentIdsForInlineAttachments(html, attachments) {
    let domDocument = new DOMParser().parseFromString(html, 'text/html');
    let imgElements = domDocument.images;
    const attachmentContentRegex = /(cid:).*?(.+?(\.gif|\.png|\.jpeg|\.jpg|\.apng|\.avif|\.svg|\.webp))+?([^"]*)/g;
    for (let iIndex = 0; iIndex < imgElements.length; iIndex++) {
      let srcAttr = imgElements[iIndex].getAttribute('src');
      if (srcAttr !== null && srcAttr !== undefined && srcAttr.includes('cid:')) {
        let attachmentName = srcAttr.split(attachmentContentRegex)[2];
        let aIndex = attachments.findIndex((attachment) => attachment.name === attachmentName);
        if (aIndex != -1) {
          attachments[aIndex].contentId = srcAttr.substring(4); // strip 'cid:'
        } else {
          logMessage('Could not find attachment for src="' + srcAttr + '" (name="' + attachmentName + '")');
        }
      }
    }
    return domDocument.documentElement.outerHTML;
  }


  static isLegacyOutlook()
  {
    var ua = window.navigator.userAgent;
    var trident = ua.indexOf('Trident/');
    if (trident > 0 && !isMailboxAPISupported()) {
      // IE 11 => return true
      // Mailbox 1.8 is not supported
      logMessage('Outlook version is legacy');
      return true
    }
    return false;
  }


  static manualMailItemGetHeaders(mailItem) {
  // Outlook 2019 - use really limited headers
  logMessage('-> manually recreate headers');
  return new Promise((resolve, reject) => {
    let headers = '';
    headers += 'Subject: ' + mailItem.subject + '\r\n';
    headers += 'From: ' + mailItem.from.displayName + ' <' + mailItem.from.emailAddress + '>\r\n';
    headers += 'Date: ' + mailItem.dateTimeCreated.toUTCString() + '\r\n';
    headers += 'To: ';
    mailItem.to.forEach(function(to, index) {
      headers += (index > 0 ? ', ' : '');
      headers += to.displayName + ' <' + to.emailAddress + '>';
    });
    headers += '\r\n';
    if (mailItem.cc.length) {
      headers += 'Cc: ';
      mailItem.cc.forEach(function(cc, index) {
        headers += (index > 0 ? ', ' : '');
        headers += cc.displayName + ' <' + cc.emailAddress + '>';
      });
      headers += '\r\n';
    }
    resolve(headers);
  });
}


static asyncMailItemGetHeaders(mailItem) {
  return new Promise((resolve, reject) => {
    mailItem.getAllInternetHeadersAsync((asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        logMessage('-> Received headers');
        resolve(asyncResult.value);
      } else {
        logMessage('-> Error getting headers: ' + asyncResult.error.name);
        reject(asyncResult.error);
      }
    });
  });
}

  /**
   * The getAllInternetHeadersAsync method was introduced in version 1.8 of the API, so it won't work in Outlook 2019.
   *
   * @param mailItem
   * @returns {Promise<unknown>}
   */
  static async mailItemGetHeaders(mailItem) {
    logMessage('MailParser.mailItemGetHeaders()');
    return this.asyncMailItemGetHeaders(mailItem)
        .then((result) => {
          if (result === '') {
            return this.manualMailItemGetHeaders(mailItem);
          } else {
            return result;
          }
        })
        .catch((e) => {
          return this.manualMailItemGetHeaders(mailItem);
        });
  }


  /**
   * @param base64String
   * @returns {any[]}
   */
  static chunk(base64String){
    const length = 76;
    const numChunks = Math.ceil(base64String.length / length)
    const chunks = new Array(numChunks)
    for (let i = 0, o = 0; i < numChunks; ++i, o += length) {
      chunks[i] = base64String.substr(o, length);
    }
    return chunks.join("\r\n");
  }


  /**
   * @param mailItem
   * @param attachments
   * @param withAttachments
   * @returns {Promise<string>}
   */
  static async createEml(mailItem, attachments, withAttachments)
  {
    logMessage('MailParser.createEml()');
    const crlf = "\r\n";
    const boundaryRelated = '_000_2c136846-60a9-4460-9d86-752cefeaba02_';
    const boundaryAlt = '_001_e6ce89af-13ea-4feb-b36b-1d94d003f304_';
    let eml = '';
    let headers = await MailParser.mailItemGetHeaders(mailItem);
    let htmlBody = await MailParser.mailItemGetBody(mailItem, Office.CoercionType.Html);
    let plainTextBody = await MailParser.mailItemGetBody(mailItem, Office.CoercionType.Text);
    htmlBody = MailParser.setContentIdsForInlineAttachments(htmlBody, attachments);

    // Start with headers
    eml += headers;
    eml += 'Content-Type: multipart/related; boundary="' + boundaryRelated + '"; type="multipart/alternative"' + crlf;
    eml += 'MIME-Version: 1.0' + crlf + crlf;

    eml += '--' + boundaryRelated + crlf;
    eml += 'Content-Type: multipart/alternative; boundary="' + boundaryAlt + '"' + crlf + crlf;

    // Append TXT message
    eml += '--' + boundaryAlt + crlf;
    eml += 'Content-Type: text/plain; charset="ascii"' + crlf;
    eml += crlf;
    eml += plainTextBody + crlf + crlf;

    // Append HTML message
    eml += '--' + boundaryAlt + crlf;
    eml += 'Content-Type: text/html; charset="utf-8"' + crlf;
    eml += crlf;
    eml += htmlBody + crlf + crlf;

    // End alternative
    eml += '--' + boundaryAlt + '--' + crlf + crlf;

    if (withAttachments) {
      // Append inline attachments
      attachments.forEach((attachment) => {
        if (attachment.isInline) {
          eml += '--' + boundaryRelated + crlf;
          eml += 'Content-Type: ' + attachment.contentType + '; name="' + attachment.name + '"' + crlf;
          eml += 'Content-Description: ' + attachment.name + crlf;
          eml += 'Content-Disposition: inline; filename="' + attachment.name + '";' + crlf;
          eml += 'Content-ID: <' + attachment.contentId + '>' + crlf;
          eml += 'Content-Transfer-Encoding: base64' + crlf;
          eml += crlf;
          eml += MailParser.chunk(attachment.content) + crlf + crlf;
        }
      });

      // Append non-inline attachments
      attachments.forEach((attachment) => {
        if (!attachment.isInline) {
          eml += '--' + boundaryRelated + crlf;
          eml += 'Content-Type: ' + attachment.contentType + '; name="' + attachment.name + '"' + crlf;
          eml += 'Content-Description: ' + attachment.name + crlf;
          eml += 'Content-Disposition: attachment; filename="' + attachment.name + '";' + crlf;
          eml += 'Content-Transfer-Encoding: base64' + crlf;
          eml += crlf;
          eml += MailParser.chunk(attachment.content) + crlf + crlf;
        }
      });
    }

    // End related
    eml += '--' + boundaryRelated + '--' + crlf;
    logMessage('-> created EML file');
    return eml;
  }


  /**
   * Small helper function to get a bootstrap icon and matching color for a mime type so user gets better recognition
   *
   * @param mime
   * @returns {{color: string, class: string}}
   */
  static getAttachmentIcon(mime)
  {
    switch (mime) {
      case 'application/pdf':
        return {class: 'bi-file-earmark-pdf', color: '#E61B23'};
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return {class: 'bi-file-earmark-word', color: '#1860B5'};
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return {class: 'bi-file-earmark-excel', color: '#0F6F39'};
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return {class: 'bi-file-earmark-ppt', color: '#CB4223'};
    }
    logMessage('Unknown mime type: ' + mime);
    return {class: 'bi-file-earmark', color: '#000000'}
  }


  /**
   * Create the category if it is in the masterCategories of the mailbox. The async call can take a couple of seconds.
   */
  static mailboxCreateCategoryIfNotExists()
  {
    logMessage('MailParser.createCategoryIfNotExists()');
    Office.context.mailbox.masterCategories.getAsync(function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        const categories = asyncResult.value;
        if (categories.findIndex((cat) => cat.displayName == MailParser.CATEGORY_NAME) !== -1) {
          logMessage('-> Category already exists');
          return;
        }
        logMessage('-> Create category');
        const masterCategoriesToAdd = [
          {displayName: MailParser.CATEGORY_NAME, color: Office.MailboxEnums.CategoryColor.Preset4},
        ];
        Office.context.mailbox.masterCategories.addAsync(masterCategoriesToAdd, function(asyncResult) {
          if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
            logMessage('-> Error adding category: ' + asyncResult.error.name);
          } else {
            logMessage('-> Created new category "' + MailParser.CATEGORY_NAME + '"');
          }
        });
      }
    });
  }


  /**
   * @param mailitem
   */
  static mailItemAddCategory(mailitem)
  {
    logMessage('MailParser.mailItemAddCategory()');
    mailitem.categories.addAsync([MailParser.CATEGORY_NAME], function (asyncResult) {
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
        throw Error('Error adding category to mailitem');
      }
    });
  }


}