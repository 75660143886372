
import {logMessage} from "../taskpane";

export class Tokenstorage
{

    /**
     * @returns {boolean}
     */
    static hasTokens() {
        return localStorage.getItem('access_token') !== null;
    }

    /**
     * @returns {boolean}
     */
    static hasAttachmentToken() {
        return localStorage.getItem('attachment_token') !== null;
    }

    /**
     * Clear tokens
     */
    static clearTokens() {
        logMessage('Tokenstorage.clearTokens()');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }

    /**
     * @param accessToken
     * @param refreshToken
     */
    static setTokens(accessToken, refreshToken) {
        logMessage('Tokenstorage.setTokens()');
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', refreshToken);
    }

    /**
     * @returns {string}
     */
    static getAccessToken() {
        if (!this.hasTokens()) {
            throw new Error('No access token set');
        }
        return localStorage.getItem('access_token');
    }

    /**
     * @returns {string}
     */
    static getRefreshToken() {
        if (!this.hasTokens()) {
            throw new Error('No tokens set');
        }
        return localStorage.getItem('refresh_token');
    }

    /**
     * @returns {string}
     */
    static getAttachmentToken() {
        logMessage('Tokenstorage.getAttachmentToken()');
        return localStorage.getItem('attachment_token');
    }

    /**
     * @returns {boolean}
     */
    static isAccessTokenExpired() {
        const accessToken = this.getAccessToken();
        const tsExp = parseInt(JSON.parse(window.atob(accessToken.split(".")[1])).exp);
        const tsCurrent = parseInt(Math.floor(new Date().getTime() / 1000));
        const expiresIn = tsExp - tsCurrent;
        return expiresIn < 10;
    }

    /**
     * @returns {boolean}
     */
    static isAttachmentTokenExpired() {
        const attachmentToken = this.getAttachmentToken();
        const tsExp = parseInt(JSON.parse(window.atob(attachmentToken.split(".")[1])).exp);
        const tsCurrent = parseInt(Math.floor(new Date().getTime() / 1000));
        const expiresInSeconds = tsExp - tsCurrent;
        return expiresInSeconds < 10;
    }

    /**
     * @param attachmentToken
     */
    static setAttachmentToken(attachmentToken)
    {
        logMessage('-> save token: ' + attachmentToken.substring(0, 12));
        localStorage.setItem('attachment_token', attachmentToken);
    }

    static refreshAttachmentTokenIfNeeded()
    {
        if (!this.hasAttachmentToken() || this.isAttachmentTokenExpired()) {
            logMessage('TokenStorage.refreshAttachmentToken()');
            return new Promise(function(resolve, reject) {
                Office.context.mailbox.getCallbackTokenAsync({ isRest: false }, function (result) {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        Tokenstorage.setAttachmentToken(result.value);
                        return resolve();
                    } else {
                        logMessage('-> failed to get token');
                        return reject('unknown error');
                    }
                });
            });
        }
    }
}