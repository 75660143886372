
import {logMessage} from "../taskpane";

export class Clusterconfig
{

    /**
     * @returns {boolean}
     */
    static isSet() {
        return localStorage.getItem('cluster_url') !== null;
    }

    /**
      * @param clusterUrl
     */
    static setUrl(clusterUrl) {
        logMessage('Set cluster URL to ' + clusterUrl);
        localStorage.setItem('cluster_url', clusterUrl);
    }

    /**
     * @returns {string}
     */
    static getUrl(){
        if (!this.isSet()) {
            throw new Error('No cluster URL configured!');
        }
        return localStorage.getItem('cluster_url');
    }

    /**
     *
     */
    static clear() {
        localStorage.removeItem('cluster_url');
    }

    /**
     * @param includeDev
     * @returns []
     */
    static getUrls(includeDev) {
        let clusters = [
            {label: 'Blauw', href: 'https://blauw.notarisdossier.nl'},
            {label: 'Geel', href: 'https://geel.notarisdossier.nl'},
            {label: 'Groen', href: 'https://groen.notarisdossier.nl'},
            {label: 'Oranje', href: 'https://oranje.notarisdossier.nl'},
            {label: 'Paars', href: 'https://paars.notarisdossier.nl'},
            {label: 'Rood', href: 'https://rood.notarisdossier.nl'},
        ];
        if (includeDev) {
            clusters.push({label: 'Test', href: 'https://testomgeving.notarisdossier.nl'});
            clusters.push({label: 'Dev', href: 'https://dev.notarisdossier-test.nl'});
        }
        return clusters;
    }

}